import { api } from 'src/boot/axios.js'

export interface TokenRequest {
  client_id: string
  grant_type: string
  username: string
  password: string
  scope: string
}

export type TokenResponse = {
  access_token: string
  expires_in: string
  token_type: string
  scope: string
  refresh_token: string
}

export interface ResetPasswordRequest {
  client_id: string
  username: string
  scope: string
}

export interface ResetPasswordResponse {
  challenge_type: string
  message: string
}

export interface ChangePasswordRequest {
  client_id: string
  username: string
  scope: 'lms'
  new_password: string
  code: string
  challenge_response: string
  challenge_type: string
}

export interface SsoLxpResponse {
  redirectUrl: string
}

export default class AuthApi {
  token = {
    post: async function (request: TokenRequest): Promise<TokenResponse> {
      const { data } = await api.post('/auth/token', request)
      return data.data
    },
  }

  resetPassword = {
    post: async function (
      request: ResetPasswordRequest,
    ): Promise<ResetPasswordResponse> {
      const { data } = await api.post('/auth/resetPassword', request)
      return data.data
    },
  }

  changePassword = {
    post: async function (request: ChangePasswordRequest) {
      const { data } = await api.post('/auth/changePassword', request)
      return data.data
    },
  }

  ssoLxp = {
    post: async function (): Promise<SsoLxpResponse> {
      const { data } = await api.post('/auth/sso/lxp', {})
      return data.data
    },
  }
}
