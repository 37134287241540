import { api as axiosApi } from 'src/boot/axios.js'
import { storeToRefs } from 'pinia'

import { usePlatformSettingStore } from 'src/stores/PlatformSetting'
import { useAuthStore } from 'src/stores/AuthStore'

import api from 'src/api'

/**
 * Retrieves the site URL based on the current window location.
 * 
 * This project is white label, and when running locally, the URL must be changed according to the platform you want to test.
 * 
 * List of URLs:
 * - 'eadim.homolog.ead.imlabs.com.br' (Mundo IM - Homolog)
 * - 'institutomix.com.br' (Mundo IM - Production)
 * - 'interativoim.ead.imlabs.com.br' (Interativo IM - Production)
 * 
 * @returns {string} The site URL.
 */
const getSiteUrl = () => {
  let siteUrl = window.location.host

  if (siteUrl === 'localhost:9000' || siteUrl === '192.168.0.195:9000' || siteUrl === 'localhost:9200' || siteUrl === '192.168.0.195:9200' || siteUrl === 'localhost:3000') {
    siteUrl = 'institutomix.com.br'
  }

  return siteUrl
}

const getShortVideos = async () => {
  const authStore = useAuthStore()
  let shortVideos = await api.shortVideos.get()

  shortVideos.forEach((shortVideo) => {
    const token = authStore.getToken
    const baseUrl = axiosApi.defaults.baseURL.replace('/api/v1', '')
    const resource = encodeURI(shortVideo.resource)

    shortVideo.resource = `${baseUrl}/video/proxy?resource=${resource}&token=${token}`
  })

  return shortVideos
}

const getPlatformSetting = async (platformId) => {
  const platformSettingStore = usePlatformSettingStore()
  const { platformSetting } = storeToRefs(platformSettingStore)

  if (platformSetting.value) {
    return platformSetting.value
  }

  const data = await api.platforms.settings.get(platformId)
  platformSetting.value = data

  return platformSetting.value
}

const startShortVideo = async (shortVideoId) => {
  await api.shortVideos.start.post(shortVideoId)
}

const endShortVideo = async (shortVideoId) => {
  await api.shortVideos.end.post(shortVideoId)
}

export {
  getSiteUrl,
  getShortVideos,
  getPlatformSetting,
  startShortVideo,
  endShortVideo,
}
