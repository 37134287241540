import { api, apiV2 } from 'src/boot/axios.js'

import { Pagination } from 'src/types/Pagination'

export interface LearningObject {
  id: string
  type: string
  name: string
  status: string
  grade: null | number
  section_number: number
  started_at: string
  ended_at: string
}
export interface Lesson {
  id: number
  name: string
  description: string
  estimated_duration: number
  progress: number
  is_completed: boolean
  grade: number
  is_graduable: number
  learning_objects: LearningObject[]
}

export interface Module {
  id: number
  name: string
  description: string
  estimated_duration: number
  progress: number
  is_completed: boolean
  grade: number
  has_passed: boolean
  is_graduable: boolean
  course_id: number
  lessons: Lesson[]
  lo_count: number
  completed_lo_count: number
}

export interface Course {
  id: number
  name: string
  short_name: string
  version: string
  platform_id: number
  original_platform_id: number
  description: string
  certification_hours: null | number
  progress: number
  is_minimum_grade_required: boolean
  minimum_grade: number
  is_minimum_progress_required: boolean
  minimum_progress: number
  is_enrollment_active: boolean
  is_available_upon_schedule: false
  available_dayofweek: null | string
  schedule_start_at: string
  schedule_end_at: string
  progress_type: number
  enrollment_review_status: number
  enrolled_at: string
  reset_at: null | string
  reset_by_user_id: null | number
  certificated_at: null | string
  certificate_key: null | string
  owner_platform_id: number
  is_completed: boolean
  grade: number
  viewed_at: string
  modules: Module[]
  is_graduable: boolean
  pointer: {
    module_id: null | number
    module_index: null | number
    lesson_id: null | number
    lesson_index: null | number
    learning_object_id: null | number
    learning_object_index: null | number
  }
  is_certifiable: boolean
  estimated_duration: number
  is_inside_scheduled_hours: boolean
  image_url: string
}

export interface Resource {
  resource: string
  type: string
  value: string | null
}

export interface ParamsSettings {
  b64: number
}
export interface GetCoursesParams {
  limit?: number
  search?: string
  sort?: string
  offset?: number
  category_id?: number
}

export interface GetCoursesResponse {
  pagination: Pagination
  data: Course[]
}

export default class LmsApi {
  settings = {
    get: async function (
      settings: string[],
      params?: Partial<ParamsSettings>,
    ): Promise<Resource[]> {
      const settingsString = settings.join(',')

      const response = await api.get(`/lms/settings/${settingsString}`, {
        params,
      })
      if (response && response.data) {
        return response.data.data;
      } else {
        throw new Error('A resposta da API não está no formato esperado');
      }
    },
  }

  courses = {
    get: async function (
      params: GetCoursesParams,
    ): Promise<GetCoursesResponse> {
      const { data } = await apiV2.get('/lms/courses', { params })
      return {
        pagination: data.pagination,
        data: data.data,
      }
    },

    getById: async function (id: number): Promise<Course> {
      const { data } = await api.get(`/lms/courses/${id}`)
      return data.data
    },
  }

  learningObjects = {
    get: async function (id: string): Promise<LearningObject> {
      const { data } = await api.get(`/lms/learningObjects/${id}`)
      return data.data
    },

    start: {
      async put(id: string) {
        const { data } = await api.put(`/lms/learningObjects/${id}/start`)
        return data.data
      },
    },
    /* eslint-disable @typescript-eslint/no-explicit-any */
    end: {
      async put(id: string, answer: any) {
        const { data } = await api.put(`/lms/learningObjects/${id}/end`, {
          data: answer,
        })
        return data.data
      },
    },
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }

  lessons = {
    reset: {
      async put(id: number) {
        const { data } = await api.put(`/lms/lessons/${id}/reset`)
        return data.data
      },
    },
  }

  modules = {
    get: async function (id: number): Promise<Module> {
      const { data } = await api.get(`/lms/modules/${id}`)
      return data.data
    },
  }
}
